import { createRouter, createWebHistory } from "vue-router";
import App from "./App.vue";
import store from "./store";

const routes = [
  {
    path: "/dashboard",
    name: "app",
    component: App,
    meta: { requiresAuth: true }, // Menandakan bahwa rute ini memerlukan autentikasi
    children: [
      {
        path: "",
        name: "dashboard",
        component: () => import("./pages/Dashboard.vue"),
      },
      {
        path: "/master-user-hp",
        name: "master-user-hp",
        component: () => import("./pages/master/UserHp.vue"),
      },
      {
        path: "/master-user-web",
        name: "master-user-web",
        component: () => import("./pages/master/UserWeb.vue"),
      },
      {
        path: "/master-approval-order",
        name: "master-approval-order",
        component: () => import("./pages/master/ApprovalOrder.vue"),
      },
      {
        path: "/database-visit",
        name: "database-visit",
        component: () => import("./pages/database/Visit.vue"),
      },
      {
        path: "/example-page",
        name: "example-page",
        component: () => import("./pages/ExamplePage.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./pages/Login.vue"),
  },
  {
    path: "/error",
    name: "error",
    component: () => import("./pages/Error.vue"),
  },
  {
    path: "/access",
    name: "access",
    component: () => import("./pages/Access.vue"),
  },
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("./pages/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem("authToken");
  const pageName = to.name;

  if (to.name === "login" && isAuthenticated) {
    next({ name: "dashboard" });
  } else {
    // Mengecek apakah rute saat ini memiliki children
    if (to.matched.some((route) => route.meta.requiresAuth)) {
      // Jika pengguna telah login, lanjutkan ke rute yang diminta
      if (isAuthenticated) {
        store.dispatch("auth/checkPermission", pageName);
        setTimeout(() => {
          if (localStorage.getItem("isPermission") == "false") {
            next({ name: "access" });
          } else {
            next();
          }
        }, 250);
      } else {
        // Jika pengguna belum login, redirect ke halaman login
        next({ name: "login" });
      }
    } else {
      // Jika rute tidak memiliki children, lanjutkan ke rute yang diminta
      next();
    }
  }
});

export default router;
